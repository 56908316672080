<template>
  <main
    class="wrap-bg"
    :style="{ backgroundImage: `url(${host}h5-active/${config.theme_id}/bg.png)` }"
  >
    <section
      class="content"
      :style="{ 
        top: styleConfig.mainTop
      }"
    >
      <h3
        :style="{
          color: styleConfig.titleColor,
          fontSize: styleConfig.titleSize,
        }"
      >充值{{ config.recharge_money / 100 || "" }}元</h3>
      <div
        v-if="config.give_type === 1 || config.give_type === '1'"
        :style="{
          color: styleConfig.coninColor,
          fontSize: styleConfig.coninSize,
          top: styleConfig.coninTop,
        }"
      >
        <KCoin :config="config" />
      </div>
      <div 
        v-else
        :style="{
          color: styleConfig.coninColor,
          fontSize: styleConfig.coninSize,
          top: styleConfig.coninTop,
        }"
      >全站短剧免费看({{ config.give_vip_days || 0 }}天)</div>
      <img
        :style="{
          top: styleConfig.buttonTop,
          width: styleConfig.buttonWidth
        }"
        class="recharge-btn"
        :src="host + `h5-active/${config.theme_id}/btn.png`"
        @click="createOrder"
      />
    </section>
    <div class="tips" :style="{color: styleConfig.tipsColor}">
      <p>-活动规则-</p>
      <p v-if="config.recharge_count != 9999">
        每一期活动单一用户仅能充值{{ config.recharge_count }}次
      </p>
      <p>虚拟商品购买后不可退换</p>
    </div>
  </main>
</template>

<script>
import { URL } from '../config'
export default {
  props: {
    config: {
      type: Object,
    },
    host: String,
    // mainTop: '38vh'
    // titleColor: '#9b4812',
    // titleSize: '32px',
    // coninColor: '#dd3c31',
    // coninSize: '24px',
    // coninTop: '23vw',
    // buttonWidth: '45vw',
    // buttonTop: '38vw',
    // tipsColor: '#dd3c31',
    styleConfig: Object
  },
  data() {
    return {
      host: URL
    }
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap-bg {
  position: relative;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}
.content {
  position: absolute;
  top: 43vh; // 变量
  text-align: center;
  width: 100vw;
  h3 {
    font-weight: bold;
    text-align: center;
    text-shadow: 0 2px 0 #fffbf2;
  }
  div {
    position: absolute;
    width: 100vw;
    white-space: nowrap;
    text-align: center;
  }
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
  }
}
.tips {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  font-size: 16px;
  color: #ff5a5a;  // 变量
  z-index: 1;
  line-height: 26px;
  font-weight: bold;
  p {
    text-align: center;
  }
}
</style>
