<template>
  <main
    class="wrap-bg"
    :style="{ backgroundImage: `url(${host}h5-active/${config.theme_id}/bg.png)` }"
  >
    <section
      class="content"
      :style="{ 
        backgroundImage: `url(${host}h5-active/${config.theme_id}/center.png)`,
        top: styleConfig.mainTop,
        width: styleConfig.mainWidth,
        height: styleConfig.mainHeight
      }"
    >
      <h3
        :style="{
          color: styleConfig.titleColor,
          fontSize: styleConfig.titleSize,
          top: styleConfig.titleTop,
        }"
      >充值{{ config.recharge_money / 100 || "" }}元</h3>
      <div 
        v-if="config.give_type === 1 || config.give_type === '1'"
        :style="{
          color: styleConfig.coninColor,
          fontSize: styleConfig.coninSize,
          top: styleConfig.coninTop,
        }"
      >
        <KCoin :config="config" />
      </div>
      <div
        :style="{
          color: styleConfig.coninColor,
          fontSize: styleConfig.coninSize,
          top: styleConfig.coninTop,
        }" 
        v-else>全站短剧免费看({{ config.give_vip_days || 0 }}天)</div>
      <img
        :style="{
          top: styleConfig.buttonTop,
          width: styleConfig.buttonWidth,
        }"
        :src="host + `h5-active/${config.theme_id}/btn.png`"
        @click="createOrder"
      />
    </section>
    <div class="tips" :style="{color: styleConfig.tipsColor}">
      <p>-活动规则-</p>
      <p v-if="config.recharge_count != 9999">
        每一期活动单一用户仅能充值{{ config.recharge_count }}次
      </p>
      <p>虚拟商品购买后不可退换</p>
    </div>
  </main>
</template>

<script>
import { URL } from '../config'
export default {
  props: {
    config: {
      type: Object,
    },
    /**
     * {
     *  mainTop: '38vh'
     *  mainWidth: '86vw',
     *  mainHeight: '60vw',
     *  titleColor: '#9b4812',
     *  titleSize: '32px',
     *  titleTop: '8vw',
     *  coninColor: '#dd3c31',
     *  coninSize: '24px',
     *  coninTop: '23vw',
     *  buttonWidth: '45vw',
     *  buttonSize: '24px',
     *  buttonTop: '38vw',
     *  tipsColor: '#dd3c31',
     * }
     */
    styleConfig: Object
  },
  data() {
    return {
      host: URL
    }
  },
  methods: {
    createOrder() {
      this.$emit("createOrder");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap-bg {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.content {
  position: absolute;
  transform: translateX(-50%);
  left: 50vw;
  background-size: cover;
  background-position: center center;
  > * {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.content h3 {
  font-weight: bold;
  text-shadow: 0 2px 0 #fffbf2;
}
.content div {
  white-space: nowrap;
}
.content img {
  white-space: nowrap;
  height: auto;
}
.tips {
  font-weight: bold;
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  font-size: 16px; 
  z-index: 1;
  line-height: 26px;
  p {
    text-align: center;
  }
}
</style>
