<template>
  <div id="active">
    <!-- Theme2 是vip会员天, 和其他的差别较大  -->
    <component 
      v-if="isTemplate" 
      :config="rechargeInfo"
      v-bind:is="themeTemplateMap[rechargeInfo.theme_id].template"
      :styleConfig="themeTemplateMap[rechargeInfo.theme_id].config"
      @createOrder="createOrder"
    />
    <component
      v-else-if="rechargeInfo.theme_id"
      v-bind:is="'Theme' + rechargeInfo.theme_id"
      :host="URL"
      :config="rechargeInfo"
      @createOrder="createOrder"
    />
    <van-popup v-model:show="show" position="bottom" :style="{ height: '160px' }">
      <div class="pay-box">
        <van-button
          icon="wechat"
          size="large"
          color="#75D6DC"
          @click="toPay(1)"
        >微信支付</van-button>
        <van-button
          icon="alipay"
          type="primary"
          size="large"
          @click="toPay(2)"
        >支付宝支付</van-button>
      </div>
    </van-popup>
  </div> 
</template>

<script>
import { Popup, Button, Toast } from 'vant'
import Theme2 from './styleTheme/theme2.vue'
import Theme26 from './styleTheme/theme26.vue'
import ThemeTemplate1 from './styleTheme/themeTemplate1.vue'
import ThemeTemplate2 from './styleTheme/themeTemplate2.vue'

import { getActivityDetail } from '../../api/active'
import { URL } from './config'

export default {
  components: {
    ThemeTemplate1,
    ThemeTemplate2,
    [Popup.name]: Popup,
    [Button.name]: Button,
    Theme2,
    Theme26,
  },
  created() {
    const {view, ...params} = this.$route.query
    if(view){
      this.rechargeInfo = params
      document.title = params.title
    }else{
      getActivityDetail({activity_id: this.activeId}).then(res => {
        this.rechargeInfo = res.data
        document.title = res.data.title
      })
    }
    try {
      // 注册接受快应用信息
      system.onmessage = (data) => {
        const {code, message} = JSON.parse(data)
        if (code === 200) {
          Toast.success(message)
          system.postMessage("{'action':'close'}")
        } else {
          Toast.fail(message)
        }
      }
    } catch(_err) {}

  },
  data() {
    return {
      activeId: this.$route.params.activeId,
      rechargeInfo: {
        rechargeCount: 1,
        theme_id: null,
      },
      themeTemplateMap: {
        1: this.getTheme1Style('38vh', '86vw', '60vw', '#9b4812', '30px','8vw', '#dd3c31', '24px', '23vw', '45vw', '38vw', '#fff'),
        5: this.getTheme1Style('44vh', '83vw', '58vw', '#0f6e5e', '30px','9vw', '#39c2a3', '24px', '23vw', '52vw', '37vw', '#096756'),
        6: this.getTheme1Style('32vh', '80vw', '81vw', '#f9e4a8', '30px','34vw', '#fff', '24px', '47vw', '52vw', '58vw', '#c02617'),
        7: this.getTheme1Style('44vh', '92vw', '58vw', '#dc171e', '30px','8vw', '#fb7d99', '22px', '22vw', '52vw', '37vw', '#d50000'),
        8: this.getTheme1Style('41vh', '90vw', '62vw', '#7081d4', '36px','9vw', '#fa7e23', '24px', '24vw', '55vw', '39vw', '#fff'),
        9: this.getTheme1Style('41vh', '90vw', '52vw', '#0799a3', '30px','8.5vw', '#0799a3', '20px', '22vw', '46vw', '34vw', '#fff'),
        10: this.getTheme1Style('41vh', '88vw', '52vw', '#e81a2d', '32px','8.5vw', '#e81a2d', '20px', '22vw', '48vw', '33vw', '#fff'),
        18: this.getTheme1Style('40vh', '84vw', '68vw', '#e38b2e', '36px','10vw', '#e38b2e', '24px', '26vw', '50vw', '42vw', '#333'),
        19: this.getTheme1Style('40vh', '84vw', '68vw', '#e38b2e', '36px','10vw', '#e38b2e', '24px', '26vw', '50vw', '42vw', '#333'),
        21: this.getTheme1Style('38vh', '88vw', '57vw', '#e38b2e', '32px','8vw', '#e38b2e', '22px', '24vw', '50vw', '38vw', '#333'),
        22: this.getTheme1Style('44vh', '88vw', '57vw', '#d51a14', '32px','8vw', '#d51a14', '22px', '24vw', '50vw', '38vw', '#ffe491'),
        27: this.getTheme1Style('44vh', '88vw', '57vw', '#e38b2e', '32px','8vw', '#c69a5f', '22px', '24vw', '50vw', '38vw', '#ffe491'),
        29: this.getTheme1Style('42vh', '84vw', '68vw', '#e38b2e', '32px','12vw', '#c69a5f', '22px', '27vw', '50vw', '44vw', '#333'),
        32: this.getTheme1Style('38vh', '84vw', '65vw', '#e38b2e', '36px','10vw', '#e38b2e', '22px', '27vw', '54vw', '42vw', '#fff'),
        33: this.getTheme1Style('38vh', '84vw', '68vw', '#e38b2e', '36px','10vw', '#e38b2e', '22px', '27vw', '56vw', '43vw', '#fff'),
        35: this.getTheme1Style('45vh', '88vw', '49vw', '#d51a14', '30px','8vw', '#e38b2e', '20px', '20vw', '48vw', '28vw', '#fff'),
        37: this.getTheme1Style('45vh', '88vw', '49vw', '#d51a14', '30px','8vw', '#e38b2e', '20px', '20vw', '48vw', '28vw', '#fff'),
        40: this.getTheme1Style('44vh', '88vw', '57vw', '#e38b2e', '32px','8vw', '#c69a5f', '22px', '23vw', '50vw', '36vw', '#ffe491'),
        41: this.getTheme1Style('44vh', '88vw', '60vw', '#e38b2e', '34px','8vw', '#c69a5f', '22px', '23vw', '52vw', '38vw', '#333'),
        42: this.getTheme1Style('44vh', '88vw', '60vw', '#c69a5f', '34px','8vw', '#c69a5f', '22px', '23vw', '55vw', '38vw', '#fff'),
        100: this.getTheme1Style('40vh', '100vw', '60vw', '#d51a14', '30px','11vw', '#c69a5f', '22px', '25vw', '48vw', '43vw', '#333'),
        101: this.getTheme1Style('38vh', '88vw', '60vw', '#d51a14', '32px','11vw', '#d51a14', '20px', '25vw', '45vw', '37vw', '#333'),
        102: this.getTheme1Style('38vh', '88vw', '60vw', '#d51a14', '32px','11vw', '#d51a14', '20px', '25vw', '45vw', '37vw', '#fff'),
        103: this.getTheme1Style('38vh', '96vw', '60vw', '#d51a14', '32px','11vw', '#d51a14', '20px', '25vw', '45vw', '42vw', '#fff'),
        105: this.getTheme1Style('38vh', '96vw', '60vw', '#d51a14', '32px','11vw', '#d51a14', '20px', '25vw', '45vw', '42vw', '#333'),
        150: this.getTheme1Style('36vh', '86vw', '69vw', '#e38b2e', '34px','11vw', '#e38b2e', '22px', '27vw', '50vw', '44vw', '#333'),
        152: this.getTheme1Style('37vh', '86vw', '69vw', '#e38b2e', '34px','11vw', '#e38b2e', '22px', '27vw', '50vw', '44vw', '#333'),
        153: this.getTheme1Style('38vh', '86vw', '69vw', '#e38b2e', '34px','11vw', '#e38b2e', '22px', '27vw', '50vw', '44vw', '#333'),
        3: this.getTheme2Style('46vh', '#ff577f', '30px', '#a91f42', '22px', '8vh', '16vh', '40vw', '#ff5a5a'),
        4: this.getTheme2Style('56.5vh', '#65b4e2', '30px', '#244b78', '22px', '8vh', '16vh', '40vw', '#fff'),
        11: this.getTheme2Style('38vh', '#b24606', '34px', '#dd3c31', '22px', '8vh', '16vh', '54vw', '#ffe491'),
        12: this.getTheme2Style('46vh', '#b24606', '34px', '#dd3c31', '22px', '9vh', '17vh', '55vw', '#ffe491'),
        13: this.getTheme2Style('59vh', '#b24606', '30px', '#dd3c31', '20px', '6vh', '11vh', '45vw', '#fff'),
        14: this.getTheme2Style('47vh', '#b24606', '28px', '#dd3c31', '20px', '6vh', '12vh', '38vw', '#fff'),
        15: this.getTheme2Style('42vh', '#b24606', '34px', '#cb7d1d', '22px', '8vh', '15vh', '45vw', '#fff'),
        16: this.getTheme2Style('40vh', '#e81a2d', '32px', '#e81a2d', '21px', '7vh', '18vh', '50vw', '#fff'),
        17: this.getTheme2Style('40vh', '#e81a2d', '34px', '#e81a2d', '21px', '8vh', '16vh', '50vw', '#fff'),
        20: this.getTheme2Style('44vh', '#e38b2e', '34px', '#c69a5f', '22px', '9vh', '18vh', '50vw', '#fff'),
        23: this.getTheme2Style('48vh', '#e38b2e', '32px', '#c69a5f', '22px', '8vh', '15vh', '50vw', '#333'),
        24: this.getTheme2Style('52vh', '#e38b2e', '34px', '#c69a5f', '22px', '8vh', '14vh', '52vw', '#666'),
        25: this.getTheme2Style('42vh', '#d51a14', '30px', '#d51a14', '22px', '7vh', '19vh', '44vw', '#ffe491'),
        28: this.getTheme2Style('54vh', '#d91104', '34px', '#dd3c31', '22px', '10vh', '18vh', '44vw', '#555'),
        30: this.getTheme2Style('42vh', '#d51a14', '34px', '#d51a14', '22px', '8vh', '16vh', '50vw', '#555'),
        31: this.getTheme2Style('49vh', '#d51a14', '30px', '#d51a14', '22px', '7vh', '15vh', '52vw', '#fff'),
        34: this.getTheme2Style('47vh', '#0f6e5e', '34px', '#39c2a3', '22px', '8vh', '16vh', '50vw', '#333'),
        36: this.getTheme2Style('42vh', '#e38b2e', '34px', '#e38b2e', '22px', '9vh', '17vh', '50vw', '#444'),
        39: this.getTheme2Style('47vh', '#e38b2e', '34px', '#e38b2e', '22px', '9vh', '17vh', '50vw', '#444'),
      },
      URL,
      show: false,
      ['IRIS-RECOGNITION']: this.$route.query['token'],
      ['app-appid']: this.$route.query['app_id'],
      ['os-deviceid']: this.$route.query['device_id'],
    }
  },
  computed: {
    isTemplate() {
      const { theme_id } =this.rechargeInfo
      console.log(theme_id, this.themeTemplateMap[theme_id]);
      return theme_id && this.themeTemplateMap[theme_id]
    }
  },
  methods: {
    createOrder() {
      // 如果是预览的 就不让其唤起支付
      if(this.$route.query.view) return
      this.show = true
    },
    // type 1为微信, 2为支付宝
    toPay(type) {
      const return_url = process.env.NODE_ENV === 'dev' ? 'http://localhost:8080/aliPay.html' : 'http://h5.xs.haidubooks.com/aliPay.html'
      const message = {
        type,
        payment_type: 2,
        recharge_id: this.rechargeInfo.id,
        return_url,
        from_type: 'ACTIVITY',
        from_code: this.activeId
      }
      try {
        system.postMessage(JSON.stringify(message))
      } catch(_err) {
      }
    },
    getTheme1Style(
      mainTop, 
      mainWidth, 
      mainHeight, 
      titleColor, 
      titleSize, 
      titleTop, 
      coninColor,
      coninSize,
      coninTop,
      buttonWidth,
      buttonTop,
      tipsColor,
    ) {
      return {
        template: 'themeTemplate1',
        config: {
          mainTop, 
          mainWidth, 
          mainHeight, 
          titleColor, 
          titleSize, 
          titleTop, 
          coninColor,
          coninSize,
          coninTop,
          buttonWidth,
          buttonTop,
          tipsColor
        }
      }
    },
    getTheme2Style(
      mainTop, 
      titleColor, 
      titleSize, 
      coninColor,
      coninSize,
      coninTop,
      buttonTop,
      buttonWidth,
      tipsColor,
    ) {
      return {
        template: 'themeTemplate2',
        config: {
          mainTop, 
          titleColor, 
          titleSize, 
          coninColor,
          coninSize,
          coninTop,
          buttonTop,
          buttonWidth,
          tipsColor,
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pay-box {
  padding: 10px 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
