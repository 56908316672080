import http from './config'

// 获取活动详情
export function getActivityDetail(params) {
  return http({
    url: '/api/v1/activity/detail',
    method: 'get',
    params
  })
}


